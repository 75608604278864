/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Activity.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Activity } from "./Activity";

import { ActivitiesOrder } from "./ActivitiesOrder";
import { Project } from "./Project";
import { VendorCache } from "./VendorCache";

export abstract class ActivityModel {
    id: number;
    activityId: string | null;
    createdAt: Dayjs;
    displayId: string | null;
    finish: Dayjs | null;
    name: string | null;
    projectId: number | null;
    secondaryVendorUniqueId: string | null;
    start: Dayjs | null;
    updatedAt: Dayjs;
    vendorUniqueId: string | null;
    wbs: string | null;
    activitiesOrders: ActivitiesOrder[] | undefined;
    project: Project | null | undefined;
    vendorCache: VendorCache | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        activityId: string | null,
        createdAt: Dayjs,
        displayId: string | null,
        finish: Dayjs | null,
        name: string | null,
        projectId: number | null,
        secondaryVendorUniqueId: string | null,
        start: Dayjs | null,
        updatedAt: Dayjs,
        vendorUniqueId: string | null,
        wbs: string | null,
        activitiesOrders: ActivitiesOrder[] | undefined,
        project: Project | null | undefined,
        vendorCache: VendorCache | null | undefined
    ) {
        this.id = id;
        this.activityId = activityId;
        this.createdAt = createdAt;
        this.displayId = displayId;
        this.finish = finish;
        this.name = name;
        this.projectId = projectId;
        this.secondaryVendorUniqueId = secondaryVendorUniqueId;
        this.start = start;
        this.updatedAt = updatedAt;
        this.vendorUniqueId = vendorUniqueId;
        this.wbs = wbs;
        this.activitiesOrders = activitiesOrders;
        this.project = project;
        this.vendorCache = vendorCache;
    }
     

     
    static fromApiObject(json: any, timezone: string): Activity {
        const newObject = new Activity(
            json["id"],
            json["activity_id"],
            dayjs(json["created_at"]).tz(timezone),
            json["display_id"],
            json["finish"] ? dayjs(json["finish"]).tz(timezone) : null,
            json["name"],
            json["project_id"],
            json["secondary_vendor_unique_id"],
            json["start"] ? dayjs(json["start"]).tz(timezone) : null,
            dayjs(json["updated_at"]).tz(timezone),
            json["vendor_unique_id"],
            json["wbs"],
            json["activities_orders"]?.map((item: any) => ActivitiesOrder.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["vendor_cache"] == null ? json["vendor_cache"] : VendorCache.fromApiObject(json["vendor_cache"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            activity_id: this.activityId,
            created_at: this.createdAt,
            display_id: this.displayId,
            finish: this.finish,
            name: this.name,
            project_id: this.projectId,
            secondary_vendor_unique_id: this.secondaryVendorUniqueId,
            start: this.start,
            updated_at: this.updatedAt,
            vendor_unique_id: this.vendorUniqueId,
            wbs: this.wbs,
            activities_orders: this.activitiesOrders?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Activity {
        const clone = Object.create(this) as Activity;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
