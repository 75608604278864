import { OrderModel } from "./Order.model";

export class Order extends OrderModel {
    // The "status" column that we use was changed to be more of a "next step" value,
    // so this function takes the column "status" and converts it to the actual current status
    // This mapping of values should be parallel to the one in "order.rb#calculate_status"
    currentStatus(): string {
        switch (this.status) {
        case "complete":
            return "RECEIVED";
        case "needs_received":
            return "SCHEDULED";
        case "needs_roj":
            return "BUDGETED";
        case "needs_material_submitted":
            return "BUDGETED";
        case "needs_material_approved":
            return "SUBMITTED";
        case "needs_ordered":
            return "APPROVED";
        case "needs_scheduled":
            return "ORDERED";
        default:
            return "BUDGETED";
        }
    }
}
