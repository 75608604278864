import Helper from "../../Helper";
import TeamInfo from "../../teamInfo";

export function getIntegrationSourceCompanies(
    team_subdomain: string,
    project_token: string
): Promise<any[] | { errors: string }> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/integrations_companies";

    const options = { method: "GET" };
    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return [];
        });
}

export function getIntegrationSourceProjects(team_subdomain: string, project_token: string): Promise<any[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/integrations_projects";

    const options = { method: "GET" };
    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return [];
        });
}

export function getIntegrationSourceVendors(
    team_subdomain: string,
    project_token: string
): Promise<any[] | { errors: string }> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/integrations_vendors";

    const options = { method: "GET" };
    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return [];
        });
}

export function getIntegrationContactsByVendor(team_subdomain: string, project_token: string): Promise<any[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/integrations_contacts_by_vendor";

    const options = { method: "GET" };
    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return [];
        });
}

export function updateIntegrationSource(
    team_subdomain: string,
    project_token: string,
    company_id: string | null,
    project_id: string | null
): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/integrations/1";

    const options = { method: "PUT", body: JSON.stringify({ company_id, project_id }) };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function deleteIntegrationSource(team_subdomain: string, project_token: string): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/integrations/1";

    const options = {
        method: "DELETE",
    };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function addTeamIntegrationLink(team: TeamInfo, project: any, vendorId: string): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team.subdomain ? team.subdomain : "projects") +
        "/projects/" +
        project.unique_token +
        "/integrations_connect_members";

    const options = {
        method: "POST",
        body: JSON.stringify({ object_id: team.id, object_type: "team", vendor_object_id: vendorId }),
    };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return null;
        });
}

export function removeTeamIntegrationLink(team: TeamInfo, project: any): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team.subdomain ? team.subdomain : "projects") +
        "/projects/" +
        project.unique_token +
        "/integrations_connect_members";

    const options = {
        method: "DELETE",
        body: JSON.stringify({ object_id: team.id, object_type: "team" }),
    };

    return Helper.plotFetch(url, options, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then((response) => {
            return response;
        })
        .catch(() => {
            return null;
        });
}
