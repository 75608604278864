import Helper from "../../Helper";
import { MaterialGroup } from "../../models/MaterialGroup";
import { plotFetch } from "../Helper/ApiRequest";
import { FilterInfo } from "../Materials/materialTypes";

export function getProjectMaterialGroups(
    team_subdomain: string,
    project_token: string,
    filterInfo: FilterInfo,
    timezone: string,
    material_group_ids?: number[],
    archived?: boolean
): Promise<MaterialGroup[]> {
    const url = new URL(`
    ${Helper.baseUrlAltSubdomain(
        team_subdomain ? team_subdomain : "projects"
    )}/projects/${project_token}/material_groups?archived=${archived ? true : false}`);

    if (material_group_ids) {
        url.searchParams.append("material_group_ids", material_group_ids.toString());
    }

    const filterUrl = filterInfo.appendedToUrl(url);

    return plotFetch(filterUrl.toString(), { method: "GET" }, true)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return [];
        })
        .then((response) => {
            return response.map((material_group: any) => MaterialGroup.fromApiObject(material_group, timezone));
        })
        .catch((error) => {
            return [];
        });
}

export function createMaterialGroup(
    team_subdomain: string,
    project_token: string,
    material_group: MaterialGroup,
    timezone: string
) {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups";

    return plotFetch(
        url,
        {
            method: "POST",
            body: JSON.stringify({ material_group: material_group.toApiObject() }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return MaterialGroup.fromApiObject(data, timezone);
        })
        .catch(() => {
            return null;
        });
}

export function updateMaterialGroup(
    team_subdomain: string,
    project_token: string,
    material_group: MaterialGroup,
    timezone: string
): Promise<MaterialGroup | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups/" +
        material_group.id;

    return plotFetch(
        url,
        {
            method: "PUT",
            body: JSON.stringify({ material_group: material_group.toApiObject() }),
        },
        true
    )
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return MaterialGroup.fromApiObject(data, timezone);
        })
        .catch(() => {
            return null;
        });
}

export function deleteMaterialGroup(
    team_subdomain: string,
    project_token: string,
    material_group: MaterialGroup
): Promise<boolean> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups/" +
        material_group.id;

    return plotFetch(url, { method: "DELETE" }, true)
        .then((data) => {
            if (data.ok) {
                return true;
            }
            throw new Error("Network error.");
        })
        .catch(() => {
            return false;
        });
}

export function updateMaterialGroupSync(
    team_subdomain: string,
    project_token: string,
    material: MaterialGroup,
    options: ("submit_date" | "approval_date")[] = []
): Promise<boolean> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/material_groups_sync/" +
        material.id;

    return plotFetch(url, { method: "PUT", body: JSON.stringify({ options: options }) }, true)
        .then((data) => {
            if (data.ok) {
                return true;
            }

            throw new Error("Network error.");
        })
        .catch(() => {
            return false;
        });
}
